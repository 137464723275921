<template>
  <div class="LifeProgressPanel">
    <WidthWrapper set-vars-on-parent />

    <div class="section">
      <div class="wheel">
        <div class="wheel-headline">
          {{ $t('wheelOfLifeTitle') }}
        </div>
        <WheelOfLife hide-legend />
        <CategoryBraindatesItem
          v-for="category of categories"
          :key="category"
          :category-id="category"
          :show-icons="false" />
      </div>
    </div>
  </div>
</template>

<translations>
  title: Your Profile
  title_no: Din Profil
  braindates: Brain dates
  braindates_no: Brain dates
  steps: Steps
  steps_no: Steg
  journeys: Journeys
  journeys_no: Reiser
  lifeskills: Lifeskills
  lifeskills_no: Ferdigheter
  wheelOfLifeTitle: Your wheel of life
  wheelOfLifeTitle_no: Ditt livshjul
</translations>

<script>
import CategoryBraindatesItem from './CategoryBraindatesItem';
import WheelOfLife from '@/modules/dashboard/components/WheelOfLife';
import WidthWrapper from '@/modules/dashboard/components/WidthWrapper';

export default {
    components: { WheelOfLife, CategoryBraindatesItem, WidthWrapper },
    computed: {
        braindates() {
            return this.$store.state.moduleApp.braindates;
        },

        stepCount() {
            const courseIds = this.$store.getters['moduleCourse/courseIds'];
            return courseIds.reduce((total, courseId) => {
                return this.$store.getters['moduleCourse/getCourseStats'](courseId).numberOfDoneSteps + total;
            }, 0);
        },

        lifeskillsCount() {
            const lifeskills = this.$store.getters['moduleApp/getLifeskillIds'];
            return lifeskills.reduce((total, lifeskillId) => {
                const stats = this.$store.getters['moduleApp/getLifeskillStats'](lifeskillId);
                if (
                    // ignore lifeskills without any courses, etc..
                    [stats.totalTheoryBraindates, stats.totalPracticeBraindates, stats.totalTheoryLifekeys].some(
                        el => el > 0
                    ) &&
                    // check that we have done everything
                    stats.totalTheoryBraindates <= stats.acquiredTheoryBraindates &&
                    stats.totalPracticeBraindates <= stats.acquiredPracticeBraindates &&
                    stats.totalTheoryLifekeys <= stats.acquiredPracticeLifekeys
                ) {
                    return total + 1;
                } else {
                    return total;
                }
            }, 0);
        },

        journeysCount() {
            const stepGroupIds = this.$store.getters['moduleCourse/stepGroupIds'];
            let count = 0;
            stepGroupIds.forEach(stepGroupId => {
                const stats = this.$store.getters['moduleCourse/getStepGroupStats'](stepGroupId);

                if (stats.theoryProgress === 1) {
                    count++;
                }
                if (stats.numberOfScenarios > 0) {
                    if (stats.numberOfScenarios === stats.scenariosDoneCount) {
                        count++;
                    }
                }
            });
            return count;
        },

        categories() {
            return Object.keys(this.$store.state.moduleApp.categories);
        }
    }
};
</script>

<style lang="scss" scoped>
.LifeProgressPanel {
}
.WidthWrapper {
    max-width: 70em;
}

.section {
    padding-left: calc(var(--widthWrapperSideWidth) * 1px);
    padding-right: calc(var(--widthWrapperSideWidth) * 1px);
    padding-top: 2em;
    padding-bottom: 2em;
}

.stats {
    display: flex;
    justify-content: space-between;
}

.StatItem {
    flex: 1;
    background-color: rgb(18, 51, 109);
    border-radius: 0.5em;
    padding: 1em;

    &:not(:first-child) {
        margin-left: 1em;
    }
}

.wheel-headline {
    font-size: 1.2em;
    padding-bottom: 2em;
}

.WheelOfLife {
    position: relative;
    padding-bottom: 2em;
}
.StatItem {
    display: flex;
}

.StatItem__icon {
    flex: 1;
    padding-right: 5px;
    fill: rgba(255, 255, 255, 0.5);
}

.StatItem__info {
    flex: 5;
}

.StatItem__content {
    font-weight: 600;
    font-size: 120%;
}

.StatItem__label {
    opacity: 0.5;
    white-space: nowrap;
}

@include media('<mobile') {
    .WheelOfLife {
        max-width: 14em;
        margin: auto;
    }
}

@include media('>mobile') {
    .WheelOfLife {
        max-width: 20em;
        margin: auto;
    }
}
</style>
