<template>
  <div class="TeamChallengeResults">
    <WidthWrapper set-vars-on-parent />
    <div class="section">
      <ul v-if="completedTeamGoals.length" class="team-goals-list">
        <TeamChallengeResult
          v-for="teamGoal of completedTeamGoals"
          :key="teamGoal.id"
          :team-goal="teamGoal"
          @dblclick.native="debuggingMode && deleteTeamGoalWithGameAndQuiz(teamGoal)"
        />
      </ul>
    </div>
  </div>
</template>

<script>
import WidthWrapper from '@/modules/dashboard/components/WidthWrapper';
import TeamChallengeResult from './TeamChallengeResult';

import apolloClient from '@/apollo-client';
import gql from 'graphql-tag';
import deleteTeamGoal from '../TeamChallenge/graphql/DeleteTeamGoal.gql';

export default {
  components: {
    WidthWrapper,
    TeamChallengeResult,
  },

  data() {
    return {
      completedTeamGoals: [],
      debuggingMode: false,
    };
  },

  async mounted() {
    await this.$store.dispatch('moduleMemoransier/refresh');

    const response = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: gql`
        query {
          teamGoalsList {
            id
            shoutout
            start
            stop
            memoGameId
            quizGameId
            quizGame {
              leaderboardReveal
            }
            teamId
            journeyId
            createdBy
          }
        }
      `,
    });

    this.completedTeamGoals = response.data.teamGoalsList.filter(teamGoal => {
      const isTeamGoalCompleted = teamGoal.quizGame && teamGoal.quizGame.leaderboardReveal;

      return isTeamGoalCompleted;
    });
  },

  methods: {
    async deleteTeamGoalWithGameAndQuiz(teamGoal) {
      try {
        if (teamGoal.memoGameId) {
          this.$store.dispatch('moduleMemoransier/deleteGame', teamGoal.memoGameId);
        }

        if (teamGoal.quizGameId) {
          this.$store.dispatch('moduleMemoransier/deleteGame', teamGoal.quizGameId);
        }

        await apolloClient.mutate({
          mutation: deleteTeamGoal,
          variables: {
            id: teamGoal.id,
          },
        });

        location.reload();
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.WidthWrapper {
  max-width: 70em;
}

.section {
  padding-left: calc(var(--widthWrapperSideWidth) * 1px);
  padding-right: calc(var(--widthWrapperSideWidth) * 1px);
  padding-top: 2em;
  padding-bottom: 2em;
}

.team-goals-list {
  list-style-type: none;
  padding: 0;
}
</style>
