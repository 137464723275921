<template>
  <div class="ButtonToggleCollapse --global-clickable">
    <svgicon
      :class="{ collapsed: collapsed }"
      class="buttonToggleCollapseIcon"
      icon="chevron-down"
      :style="{ fill: fill }" />
  </div>
</template>

<script>
export default {
    props: {
        collapsed: {
            type: Boolean,
            default: true
        },
        fill: {
            type: String,
            default: ''
        }
    }
};
</script>

<style lang="scss" scoped>
.ButtonToggleCollapse {
    $size: 3em;
    width: $size;
    height: $size;
    background-color: rgba(black, 0.03);
    border: 1px solid rgba(black, 0.02);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.buttonToggleCollapseIcon {
    $size: 1em;
    width: $size;
    height: $size;
    fill: black;
    transition: transform 0.5s;
    transform: rotate(-180deg);

    &.collapsed {
        transform: rotate(0deg);
    }
}
</style>
