<template>
  <div class="SettingsPanel">
    <div class="header"> {{ $t('yourSettings') }} </div>

    <div class="tabs">

      <div class="tab">
        <div class="tabTitles">
          <div class="tabTitle"> {{ $t('tab1Title') }} </div>
          <div class="tabSubtitle"> {{ $t('tab1Subtitle') }}  </div>
        </div>
        <div @click="gotoAccount()" class="tabButton">
          <div class="buttonTitle"> {{ $t('tab1ButtonTitle') }} </div>
        </div>
      </div>

      <div class="tab" :class="{tabDelete: true}">
        <div class="tabTitles">
          <div class="tabTitle"> {{ $t('tab2Title') }} </div>
          <div class="tabSubtitle"> {{ $t('tab2Subtitle') }}  </div>
        </div>
        <div @click="openPopup()" class="tabButton">
          <div class="buttonTitle"> {{ $t('tab2ButtonTitle') }} </div>
        </div>
      </div>

    </div>
    
  </div>
</template>

<translations>

yourSettings: Your Settings
yourSettings_no: Innstillinger

tab1Title: Edit account details
tab1Title_no: Kontoinformasjon

tab1Subtitle: Edit or view your account details, your membership plan and payments
tab1Subtitle_no: Rediger eller se kontoinformasjonen din, medlemsplanen og betalingene dine

tab1ButtonTitle: View account
tab1ButtonTitle_no: Se konto

tab2Title: Delete your account
tab2Title_no: Slett konto

tab2Subtitle: By deleting your account you will permanently lose all of your progress
tab2Subtitle_no: Ved å slette kontoen vil du for alltid ha mistet fremgangen din

tab2ButtonTitle: Delete account
tab2ButtonTitle_no: Slett konto

</translations>

<script>
import SettingsPanelDeletePopup from './SettingsPanelDeletePopup';

export default {
  
    methods: {
        async gotoAccount() {
            const portalUrl = await this.$store.dispatch('moduleAuth/getPortalUrl');
            window.location.href = portalUrl;
        },
        openPopup() {
            this.$modal.open({
                component: SettingsPanelDeletePopup,
                hostOptions: { maskColor: 'rgba(0, 0, 0, 0.4)' },
                target: 'app-modal'
            });
      },
    }
}
</script>

<style lang="scss" scoped>

    .SettingsPanel {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        @media (max-width: 1340px) {
        justify-content: center;
        }

        .header {
            width: 70em;
            padding-bottom: 1.5em;
            opacity: 0.9;
            @media (max-width: 1090px) {
                width: 95%;
            }
        }

        .tab {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height: 7em;
            width: 70em;
            padding: 2em;
            background-color: #3838a010;
            margin-bottom: 1em;
            
            @media (max-width: 1090px) {
                width: 95vw;
                padding: 1.5em;
            }

            .tabTitles {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                height: 2.7em;

                @media (max-width: 1090px) {
                    width: 60vw;
                }
                @media (max-width: 620px) {
                    height: 3.6em;
                }

                .tabTitle {
                    opacity: 0.9;
                    padding-bottom: 0.2em;
                }

                .tabSubtitle {
                    font-size: 0.85em;
                    color: #758ebf;
                }
            }
            

            .tabButton {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                width: 12em;
                height: 3.5em;
                border-radius: 0.3em;
                background-color: #2d297e2d;
                margin-left: 1em;

                @media (max-width: 620px) {
                    width: 7.5em;
                    padding: 1em;
                }

                &:hover {
                    cursor: pointer;
                }
                .buttonTitle {
                    opacity: 0.5;
                }

            }

            &.tabDelete {
                .buttonTitle {
                    color: #EC4F48;
                    opacity: 0.95;
                }
            }
        }
    }

</style>