<template>
  <div class="FollowingLifeskillsPanel">
    <div class="header">
      <div
        class="editButton --global-clickable"
        @click="modifyFollowedLifeskills()">
        {{ $t('change') }}
      </div>
    </div>

    <div class="items">
      <div
        v-for="ls of followedLifeskills"
        :key="ls"
        class="ls-item box">
        <DashboardLifeskillItem
          :lifeskill-id="ls"
          class="--global-clickable" />
        <div class="progressBar">
          <div
            class="progressBarIndicator"
            :style="{ width: `${lifeskillProgress(ls) * 100}%` }" />
        </div>
      </div>
    </div>
  </div>
</template>

<translations>
  following: Following
  following_no: Følger
  change: change
  change_no: endre
</translations>

<script>
import DashboardLifeskillItem from '@/modules/dashboard/components/DashboardLifeskillItem';
import userProgress from '@/medals';
import { mapGetters } from 'vuex';

export default {
    components: { DashboardLifeskillItem },
    data() {
        return {
        };
    },

    computed: {
      ...mapGetters({
        followedLifeskills: 'moduleApp/getMarkedLifeskills'
      }),
    },

    methods: {
        modifyFollowedLifeskills() {
            this.$router.push({
                name: 'FollowLifeskillView'
            });
        },
        lifeskillProgress(ls) {
            const p = userProgress(ls);

            return (p.bronze.completion + p.silver.completion + p.gold.completion) / 300;
        }
    }
};
</script>

<style lang="scss" scoped>
.FollowingLifeskillsPanel {
    padding: 2em;
}

.header {
    display: flex;
    justify-content: flex-end;
    max-width: 70em;
    margin: auto;
}
.editButton {
    background-color: $standardButtonColor;
    padding: 0.5em 1.8em;
    color: white;
    border-radius: 2em;
}
.items {
    display: grid;
    justify-content: center;
    grid-gap: 2em;
    grid-template-columns: repeat(auto-fill, 12em);
    max-width: 70em;
    margin: auto;
    margin-top: 1em;
}

// -----------------------------------------------
// Progress bar
// -----------------------------------------------

.progressBar {
    background-color: rgba(black, 0.1);
    border-radius: 2em;
    width: 100%;
    overflow: hidden;
    // max-width: 30em;
    display: inline-block;
}
.progressBarIndicator {
    background-color: $standardButtonColor; //rgba(white, 0.2);
    height: 0.5em;
}
.progressBarText {
    color: white;
    height: 100%;
    padding-right: 0.5em;
    padding-left: 0.5em;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;

    & > * {
        font-size: 80%;
    }
}

// -----------------------------------------------
// 2 column layout with scaling
// -----------------------------------------------

@include media('<480px') {
    .items {
        grid-template-columns: repeat(auto-fit, 1fr);
    }
}
</style>
