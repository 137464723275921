<template>
  <div class="LifeskillNumbershapeWithProgress">
    <AnimatedNumbershape :number="lifeskillId" :play="play" color="white" reversed />
    <CircularProgress
      v-if="lifekeyProgress"
      class="lifekeyProgress"
      :progress="lifekeyProgress"
      track-color="#ffffff08"
      :color="theme.color"
      :stroke-width="3"
    />
  </div>
</template>

<script>
import CircularProgress from '@/components/CircularProgress'
import AnimatedNumbershape from '@/components/AnimatedNumbershape'

export default {
  components: { CircularProgress, AnimatedNumbershape },
  inject: ['theme'],
  props: {
    lifeskillId: {
      type: String,
      required: true,
    },
    play: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    stats() {
      return this.$store.getters['moduleApp/getLifeskillStats'](this.lifeskillId)
    },
    lifekeyProgress() {
      return this.stats.progress
    },
  },
}
</script>

<style lang="scss" scoped>
.LifeskillNumbershapeWithProgress {
  position: relative;
  background-color: rgba(black, 0.3);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AnimatedNumbershape {
  $size: 80%;
  width: $size;
  height: $size;
}

.lifekeyProgress {
  $offset: 0em;
  position: absolute;
  top: -$offset;
  right: -$offset;
  bottom: -$offset;
  left: -$offset;
}
</style>
