<template>
  <div class="TeamPanel">
    <TeamProgress />
  </div>
</template>

<script>
import TeamProgress from './TeamProgress';
export default {
    components: { TeamProgress }
};
</script>
