<template>
  <div class="TeamChallengeJourneyIcon">
    <svgicon :icon="isPractice  ? 'practice_badge_color' : 'theory_badge_color'" :original="true" class="icon" />
    <div v-if="label" class="label">
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: [Number, String],
      default: '',
    },

    isPractice: {
      type: Boolean,
      required: false,
      default: false
    }
  },
};
</script>

<style lang="scss" scoped>
.TeamChallengeJourneyIcon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .icon {
    width: 100%;
    height: 100%;
  }
  .label {
    font-size: 100%;
    font-weight: 600;
    color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    @media (max-width: 530px) {
      font-size: 10px;
    }
  }
}
</style>
