<template>
  <div class="TeamCarousel">
    <div class="carousel-wrapper">
      <div class="carousel-top-section">
        <h3 class="leaderboard-title">
          Leaderboard
        </h3>
        <div class="direction-buttons-wrapper">
          <button
            class="direction-button"
            @click="moveCarouselRight">
            <svgicon
              class="carousel-icon"
              icon="carousel-arrow-left" />
          </button>
          <button
            class="direction-button"
            @click="moveCarouselLeft">
            <svgicon
              class="carousel-icon"
              icon="carousel-arrow-right" />
          </button>
        </div>
      </div>

      <div
        v-touch:swipe.right="moveCarouselRight"
        v-touch:swipe.left="moveCarouselLeft"
        class="carousel-slider-wrapper">
        <div
          class="carousel-slider"
          :style="translateX">
          <carousel-item
            v-for="item in items"
            :key="item.avatar"
            :item="item" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CarouselItem from './CarouselItem';

export default {
    components: {
        CarouselItem
    },
    props: {
        items: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            carouselPosition: 0,
            carouselMaxLeftPosition: 0,
            carouselSingleMoveLength: 216,
            visibleItems: null
        };
    },
    computed: {
        translateX() {
            return `transform: translateX(${this.carouselPosition}px)`;
        },
        carouselMaxRightPosition() {
            return -this.carouselSingleMoveLength * (this.items.length - this.visibleItems);
        }
    },

    created() {
        this.setVisibleItemsCount();

        window.addEventListener('resize', this.setVisibleItemsCount);
    },
    methods: {
        moveCarouselLeft() {
            if (this.carouselPosition > this.carouselMaxRightPosition) {
                this.carouselPosition -= this.carouselSingleMoveLength;
            }
        },
        moveCarouselRight() {
            if (this.carouselPosition < this.carouselMaxLeftPosition) {
                this.carouselPosition += this.carouselSingleMoveLength;
            }
        },
        setVisibleItemsCount() {
            const screenWidth = window.screen.width;

            if (screenWidth >= 1241) {
                this.visibleItems = 4;
            } else if (screenWidth >= 968) {
                this.visibleItems = 3;
            } else if (screenWidth >= 695) {
                this.visibleItems = 2;
            } else {
                this.visibleItems = 1;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.TeamCarousel {
    background-color: #102e68;
    padding-bottom: 3em;
    display: flex;
    justify-content: center;
    .carousel-wrapper {
        width: 80vw;
        max-width: 70em;
        overflow: hidden;
        .carousel-top-section {
            display: flex;
            justify-content: space-between;
            .leaderboard-title {
                font-size: 22px;
                margin-top: 0;
                margin-bottom: 0.7em;
            }
            .direction-buttons-wrapper {
                display: flex;
                button:not(:last-child) {
                    margin-right: 0.8em;
                }
                .direction-button {
                    width: 22px;
                    height: 22px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: inherit;
                    border: none;
                    border-radius: 0.3em;
                    cursor: pointer;
                    outline: none;
                    &:hover {
                        background: rgba(140, 171, 255, 0.15);
                    }
                    .carousel-icon {
                        width: 0.7em;
                        height: 0.9em;
                        fill: $purple;
                    }
                }
            }
        }
        .carousel-slider {
            display: flex;
            transition: 0.5s;
        }
    }
}
</style>
