<template>
  <li class="TeamChallengeResult">
    <div class="left-side">
      <TeamChallengeJourneyIcon :label="journey.index + 1" class="journey-icon" />
      <div class="headers-wrapper">
        <h4>{{ $t(`dashboard.general.lifeskillLabels.${course.lifeskillNumber}`) }}</h4>
        <h2>{{ journey.name }}</h2>
      </div>
    </div>
    <div class="right-side">
      <div v-if="gameTopPlayer" class="top-game-player">
        <h5>TOP Game</h5>
        <div class="avatar-and-name">
          <img class="golden-rank-icn" src="@/assets/images/golden-rank-icn.png" alt="golden-rank-icon" />
          <Avatar class="avatar" :avatar="gameTopPlayer.avatar" :alt="gameTopPlayer.lastName" />
          <p>{{ gameTopPlayer.firstName }} {{ gameTopPlayer.lastName || '' }}</p>
        </div>
      </div>

      <div class="top-quiz-player">
        <h5>TOP Quiz</h5>
        <div class="avatar-and-name">
          <img class="golden-rank-icn" src="@/assets/images/golden-rank-icn.png" alt="golden-rank-icon" />
          <Avatar class="avatar" :avatar="quizTopPlayer.avatar" :alt="gameTopPlayer.lastName" />
          <p>{{ quizTopPlayer.firstName }} {{ quizTopPlayer.lastName || '' }}</p>
        </div>
      </div>

      <div class="leaderboards">
        <button @click="openLeaderBoardPopup(teamGoal.memoGameId)"> Game Leaderboard </button>
        <button @click="openLeaderBoardPopup(teamGoal.quizGameId)"> Quiz Leaderboard </button>
      </div>
    </div>
  </li>
</template>

<script>
import { mapGetters } from 'vuex';
import LeaderBoard from '@/modules/dashboard/views/TeamChallenge/LeaderBoard';
import TeamChallengeJourneyIcon from '../TeamChallenge/TeamChallengeJourneyIcon';
import Avatar from '@/components/Avatar';

export default {
  components: {
    TeamChallengeJourneyIcon,
    Avatar,
  },

  props: {
    teamGoal: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      gameLeaderBoard: [],
      quizLeaderBoard: [],
    };
  },

  computed: {
    ...mapGetters({
      getJourneyById: 'moduleCourse/getJourneyById',
    }),

    teamGoalQuiz() {
      return this.$store.getters['moduleMemoransier/games'].find(game => game.id === this.teamGoal.quizGameId);
    },

    course() {
      return this.$store.state.moduleCourse.courses[this.journey && this.journey.courseId];
    },

    journey() {
      return this.getJourneyById(this.teamGoal && this.teamGoal.journeyId);
    },

    gameTopPlayer() {
      return this.gameLeaderBoard && this.gameLeaderBoard.length && this.gameLeaderBoard[0];
    },

    quizTopPlayer() {
      return this.quizLeaderBoard && this.quizLeaderBoard.length && this.quizLeaderBoard[0];
    },
  },

  async mounted() {
    await this.refreshLeaderBoard();

    this.intervalId = setInterval(async () => {
      await this.refreshLeaderBoard();
    }, 1000);
  },

  beforeDestroy() {
    clearInterval(this.intervalId);
  },

  methods: {
    async refreshLeaderBoard() {
      if (this.teamGoal.memoGameId) {
        this.gameLeaderBoard = await this.$store.dispatch('moduleMemoransier/getLeaderboard', this.teamGoal.memoGameId);
      }

      if (this.teamGoal.quizGameId) {
        this.quizLeaderBoard = await this.$store.dispatch('moduleMemoransier/getLeaderboard', this.teamGoal.quizGameId);
      }
    },

    openLeaderBoardPopup(gameId) {
      this.$modal.open({
        component: LeaderBoard,
        props: {
          gameId,
          teamGoal: this.teamGoal,
        },
        target: 'app-modal',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.TeamChallengeResult {
  background: #18194c;
  box-shadow: 0px 2px 23px rgba(79, 79, 79, 0.1);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 20px 0;

  .Avatar {
    width: 34px;
    height: 34px;
    border-radius: 50%;
  }

  .left-side {
    min-height: 100%;
    margin-left: 15px;
    display: flex;
    align-items: center;
    @media (max-width: 380px) {
      margin-left: 5px;
    }

    .journey-icon {
      min-width: 70px;
      min-height: 70px;
      max-width: 70px;
      max-height: 70px;
      @media (max-width: 750px) {
        min-width: 65px;
        min-height: 65px;
        max-width: 65px;
        max-height: 65px;
      }
    }

    .image-wrapper {
      position: relative;
      width: 80px;
      height: 80px;
      .journey-index {
        position: absolute;
        margin: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 8px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }

    .headers-wrapper {
      margin: 0 15px;
      @media (max-width: 750px) {
        margin: 0 0 0 15px;
      }
      h4 {
        font-size: 14px;
        line-height: 22px;
        color: #758ebf;
        font-weight: normal;
        margin: 0;
        @media (max-width: 750px) {
          font-size: 12px;
        }
      }
      h2 {
        font-weight: 500;
        font-size: 14px;
        color: white;
        margin: 5px 0;
        @media (max-width: 750px) {
          font-size: 12px;
        }
      }
    }
  }

  .right-side {
    display: flex;
    align-items: center;

    .top-game-player {
      margin-right: 20px;
      @media (max-width: 1000px) {
        display: none;
      }
      h5 {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        color: #48546f;
        margin-bottom: 10px;
        margin-top: 0;
      }
      .avatar-and-name {
        display: flex;
        align-items: center;
        .golden-rank-icn {
          width: 27px;
          height: 27px;
          margin-right: 10px;
        }
        p {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          margin: 0 0 0 10px;
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }

    .top-quiz-player {
      @media (max-width: 1000px) {
        display: none;
      }
      h5 {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        color: #48546f;
        margin-bottom: 10px;
        margin-top: 0;
      }
      .avatar-and-name {
        display: flex;
        align-items: center;
        .golden-rank-icn {
          width: 27px;
          height: 27px;
          margin-right: 10px;
        }
        img {
          width: 34px;
          height: 34px;
          border-radius: 50%;
          &.avatar {
            background-color: white;
          }
        }
        p {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          margin: 0 0 0 10px;
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }

    .leaderboards {
      display: flex;
      flex-direction: column;
      margin: 0 15px 0 30px;
      @media (max-width: 380px) {
        margin: 0 10px 0 15px;
      }
      button {
        border: none;
        background: #18194c;
        color: #40ace0;
        font-weight: 500;
        font-size: 14px;
        outline: none;
        cursor: pointer;
        margin: 2px 0;
        @media (max-width: 750px) {
          font-size: 12px;
          padding: 5px 0;
        }
      }
    }
  }
}
</style>
