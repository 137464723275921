<template>
    <div class="TeamGame">
        <button v-if="!games.length" class="button-blue btn" @click="createQuizGame"> Create quiz game </button>

        <button v-if="!games.length" class="button-blue btn" @click="showPopup = true">
            {{ $t('createGame') }}
        </button>

        <ul v-if="showPopup" class="game-select-popup">
            <li v-for="dataId of dataIds" :key="dataId" @click="createGame(dataId)">
                {{ dataId }}
            </li>
        </ul>

        <button v-for="game in games" :key="game.id" class="button-blue" @click="deleteGame(game.id)">
            {{ $t('deleteGame') }}
        </button>

        <button
            v-for="game in unPublishedGames"
            :key="'up' + game.id"
            class="button-blue"
            @click="publishGame(game.id)"
        >
            {{ $t('publishGame') }}
        </button>

        <button v-for="game in publishedGames" :key="'sg' + game.id" class="button-blue" @click="gotoGame(game.id)">
            {{ $t('playGame') }}
        </button>
    </div>
</template>

<translations>
    createGame: 'Create game'
    createGame_no: 'Skape spill'
    deleteGame: 'Delete game'
    deleteGame_no: 'Slett spill'
    publishGame: 'Publish game'
    publishGame_no: 'Publiser spill'
    playGame: 'Play game'
    playGame_no: 'Spill'
</translations>

<script>
export default {
    props: {
        courseId: {
            type: String,
            required: true
        },
        stepGroupId: {
            type: String,
            required: true
        },
        teamId: {
            type: Number,
            required: true
        }
    },

    computed: {
        games() {
            return this.$store.getters['moduleMemoransier/games'].filter(game => {
                const options = JSON.parse(game.options);
                return (
                    options.teamId === this.teamId &&
                    options.courseId === this.courseId &&
                    options.stepGroupId === this.stepGroupId
                );
            });
        },

        publishedGames() {
            return this.games.filter(game => game.published);
        },

        unPublishedGames() {
            return this.games.filter(game => !game.published);
        }
    },

    mounted() {
        this.$store.dispatch('moduleMemoransier/refresh');
    },

    methods: {
        async createGame(courseId, stepGroupId) {
            const options = {
                name: 'name',
                role: '',
                options: {
                    teamId: this.teamId,
                    courseId: courseId,
                    stepGroupId: stepGroupId
                }
            };
            this.$store.dispatch('moduleMemoransier/createGame', options);
        },

        deleteGame(gameId) {
            this.$store.dispatch('moduleMemoransier/deleteGame', gameId);
        },

        publishGame(gameId) {
            this.$store.dispatch('moduleMemoransier/publishGame', gameId);
        },

        unpublishGame(gameId) {
            this.$store.dispatch('moduleMemoransier/unpublishGame', gameId);
        },

        startGame(gameId) {
            this.$store.dispatch('moduleMemoransier/startGame', gameId);
        },

        endGame(gameId) {
            this.$store.dispatch('moduleMemoransier/endGame', gameId);
        },

        gotoGame(gameId) {
            this.$router.push({
                name: 'MemoransierLobby',
                params: { gameId }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.TeamGame {
    display: flex;
    .button-blue {
        font-weight: 500;
        font-size: 14px;
        background: #3763dc;
        border-radius: 4.76651px;
        padding: 0 1em;
        margin-left: 0.5em;
        cursor: pointer;
        outline: none;
    }
}
</style>
