<template>
  <ThumbBase
    class="DashboardLifeskillItem"
    :image="backgroundImage"
    :title="label"
    :ellipsis="false"
    :data-dbg="lifeskillId"
    @click="customClickHandling || gotoLifeskill()"
    @mouseover.native="hasHover = true"
    @mouseleave.native="hasHover = false">
    <LifeskillNumberTag
      slot="tags"
      class="tag"
      :lifeskill-id="lifeskillId" />
    <LifeskillNumbershapeWithProgress
      v-if="showNumbershape"
      slot="center"
      :lifeskill-id="lifeskillId"
      class="centralIcon"
      :play="hasHover" />
  </ThumbBase>
</template>

<translations>
  metaSkill: 'Meta-skill'
  metaSkill_no: 'Meta-ferdighet'

  partOf: 'Part of'
  partOf_no: 'Del av'

  # customLifeskillLabels:

</translations>

<script>
import ThumbBase from './ThumbBase';
import LifeskillNumberTag from './LifeskillNumberTag';
import LifeskillNumbershapeWithProgress from './LifeskillNumbershapeWithProgress';
import categoryThemes from '@/category-themes';
import { getCategoryIdFromLifeskillId } from '@/utils';

const images = require.context('@/assets/images/', true, /\.(png|jpg)$/);

export default {
    components: { ThumbBase, LifeskillNumberTag, LifeskillNumbershapeWithProgress },
    props: {
        lifeskillId: {
            type: String,
            required: true
        },
        customClickHandling: {
            type: Boolean,
            default: false
        },
        showNumbershape: {
            type: Boolean,
            default: true
        }
    },
    provide() {
        return {
            theme: this.theme
        };
    },
    data() {
        return {
            theme: Object.assign({}, categoryThemes[getCategoryIdFromLifeskillId(this.lifeskillId)]),

            hasHover: false
        };
    },
    computed: {
        lifeskill() {
            return this.$store.getters['moduleApp/getLifeskillById'](this.lifeskillId);
        },
        backgroundImage() {
            try {
                return images(`./lifeskill-backgrounds-small/${this.lifeskillId}.jpg`);
            } catch (err) {
                return null;
            }
        },
        label() {
            if (this.$te(`customLifeskillLabels.${this.lifeskillId}`)) {
                return this.$t(`customLifeskillLabels.${this.lifeskillId}`);
            } else {
                return this.$t(`dashboard.general.lifeskillLabelsSoftbreak.${this.lifeskillId}`);
            }
        }
    },
    methods: {
        gotoLifeskill() {
            this.$router.push({
                name: 'DashboardLifeskillView',
                params: {
                    lifeskillId: this.lifeskillId
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.centralIcon {
    $size: 5em;
    width: $size;
    height: $size;
    margin-top: 5em;
    align-self: center;
}

.tag {
    position: absolute;
    top: 1em;
    left: 1em;
}
</style>
