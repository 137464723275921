<template>
    <div class="DashboardProfileView">
        <ProfileHeader />
        <ProfileMenu :tab-id="tabId" />

        <LifeProgressPanel v-if="tabId === 'life'" />
        <WeeklyProgressPanel v-else-if="tabId === 'week'" />
        <FollowingLifeskillsPanel v-else-if="tabId === 'follow'" />
        <TeamPanel v-else-if="tabId === 'team'" />
        <TeamPanelGoal v-else-if="tabId === 'team2'" />
        <TeamChallengeResults v-else-if="tabId === 'results'" />
        <SettingsPanel v-else-if="tabId === 'settings'" />
    </div>
</template>

<script>
import LifeProgressPanel from './LifeProgressPanel';
import WeeklyProgressPanel from './WeeklyProgressPanel';
import FollowingLifeskillsPanel from './FollowingLifeskillsPanel';
import TeamPanel from '@/modules/team/TeamPanel';
import TeamPanelGoal from '@/modules/team/TeamPanelGoal';
import ProfileHeader from '@/modules/dashboard/views/DashboardProfileView/ProfileHeader';
import ProfileMenu from '@/modules/dashboard/views/DashboardProfileView/ProfileMenu';
import TeamChallengeResults from '@/modules/dashboard/views/DashboardProfileView/TeamChallengeResults';
import SettingsPanel from './SettingsPanel';

export default {
    components: {
        LifeProgressPanel,
        WeeklyProgressPanel,
        FollowingLifeskillsPanel,
        TeamPanel,
        TeamPanelGoal,
        ProfileHeader,
        ProfileMenu,
        TeamChallengeResults,
        SettingsPanel
    },
    props: {
        tabId: {
            type: String,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
.DashboardProfileView {
    color: white;
    overflow-y: auto;
}
</style>
