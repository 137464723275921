<template>
  <div class="ProfileHeader">
    <WidthWrapper set-vars-on-parent />

    <div class="section">
      <div class="user-info-wrapper">
        <ProfileImage />
        <div class="user-info">
          <h2>{{ fullName }}</h2>
          <div class="belt-info-wrapper">
            <div
              v-if="belt.current"
              class="beltName">
              <span class="beltGrade">{{ $t(`belts.beltGrades.${belt.current.id}`) }}</span>
              {{ $t('belt') }} {{ belt.current.number || '' }}
            </div>
            <div
              v-else
              class="beltName beltName--noBelt">
              {{ $t('noBelt') }}
            </div>
            <button @click="areKeysVisible = !areKeysVisible">
              <svgicon
                class="icon"
                icon="belt-arrow"
                :style="{ transform: areKeysVisible ? '' : 'rotate(180deg)', transition: '.3s' }" />
            </button>
          </div>

          <transition
            name="fadeHeight"
            mode="out-in">
            <div
              v-if="belt.next && areKeysVisible"
              class="lifekeys">
              <Lifekey
                v-for="i of belt.next.requiredPoints"
                :key="i"
                class="lifekey"
                :completed="i <= belt.points"
                :class="{ completed: i <= belt.points }" />
            </div>
          </transition>
        </div>
      </div>
      <div class="user-statistics-wrapper">
        <div class="statistic-item">
          <h3>{{ lifeskillsCount }}</h3>
          <p> Skills </p>
        </div>
        <div class="statistic-item">
          <h3>{{ journeysCount }}</h3>
          <p>{{ $t('lifekeys') }}</p>
        </div>
        <div class="statistic-item">
          <h3>{{ braindates.length }}</h3>
          <p>{{ $t('brainDates') }}</p>
        </div>
        <div class="statistic-item">
          <h3>{{ crystals }}</h3>
          <p>{{ $t('crystals') }}</p>
        </div>
        <div class="statistic-item">
          <h3>{{ lifepoints }}</h3>
          <p>{{ $t('lifepoints') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<translations>
  belt: 'belt'
  belt_no: 'belte'
  journeys: 'Journeys'
  journeys_no: 'Reiser'
  steps: 'Steps'
  steps_no: 'Steg'
  brainDates: 'Brain Dates'
  brainDates_no: 'Hjernedates'
  lifekeys: 'Life keys'
  lifekeys_no: 'Livsnøkler'
  crystals: 'Crystals'
  crystals_no: 'Krystaller'
  lifepoints: 'Life Points'
  lifepoints_no: 'Livspoeng'
</translations>

<script>
import WidthWrapper from '@/modules/dashboard/components/WidthWrapper';
import ProfileImage from '@/modules/dashboard/components/ProfileImage';
import Lifekey from '@/modules/dashboard/components/Lifekey';
import { crystalCount } from '@/store/crystal';

export default {
    components: { WidthWrapper, ProfileImage, Lifekey },
    data() {
        return {
            areKeysVisible: true,
            crystals: 0,
        };
    },
    mounted() {
      crystalCount().then(c => this.crystals = c);
    },
    computed: {
        lifepoints() {
          return this.crystals + this.braindates.length;
        },
        belt() {
            return this.$store.getters['moduleApp/belt'];
        },
        profile() {
            return this.$store.getters['moduleAuth/profile'];
        },
        fullName() {
            const firstName = this.profile.first_name || '';
            const lastName = this.profile.last_name || '';

            return firstName + ' ' + lastName;
        },
        lifeskillsCount() {
            const lifeskills = this.$store.getters['moduleApp/getLifeskillIds'];
            return lifeskills.reduce((total, lifeskillId) => {
                const stats = this.$store.getters['moduleApp/getLifeskillStats'](lifeskillId);
                if (
                    // ignore lifeskills without any courses, etc..
                    [stats.totalTheoryBraindates, stats.totalPracticeBraindates, stats.totalTheoryLifekeys].some(
                        el => el > 0
                    ) &&
                    // check that we have done everything
                    stats.totalTheoryBraindates <= stats.acquiredTheoryBraindates &&
                    stats.totalPracticeBraindates <= stats.acquiredPracticeBraindates &&
                    stats.totalTheoryLifekeys <= stats.acquiredPracticeLifekeys
                ) {
                    return total + 1;
                } else {
                    return total;
                }
            }, 0);
        },
        journeysCount() {
            const stepGroupIds = this.$store.getters['moduleCourse/stepGroupIds'];
            let count = 0;
            stepGroupIds.forEach(stepGroupId => {
                const stats = this.$store.getters['moduleCourse/getStepGroupStats'](stepGroupId);
                if (stats.theoryProgress === 1) {
                    count++;
                }
                if (stats.numberOfScenarios > 0) {
                    if (stats.numberOfScenarios === stats.scenariosDoneCount) {
                        count++;
                    }
                }
            });
            return count;
        },
        stepCount() {
            const courseIds = this.$store.getters['moduleCourse/courseIds'];
            return courseIds.reduce((total, courseId) => {
                return this.$store.getters['moduleCourse/getCourseStats'](courseId).numberOfDoneSteps + total;
            }, 0);
        },
        braindates() {
            return this.$store.state.moduleApp.braindates;
        }
    }
};
</script>

<style lang="scss" scoped>
.fadeHeight-enter-active,
.fadeHeight-leave-active {
    transition: all 0.2s;
    max-height: 100px;
    overflow: hidden;
}
.fadeHeight-enter,
.fadeHeight-leave-to {
    max-height: 0px;
}

.WidthWrapper {
    max-width: 70em;
}

.section {
    padding-left: calc(var(--widthWrapperSideWidth) * 1px);
    padding-right: calc(var(--widthWrapperSideWidth) * 1px);
    padding-top: 2em;
    padding-bottom: 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 915px) {
        flex-direction: column;
    }
    .user-info-wrapper {
        display: flex;
        @media (max-width: 700px) {
            flex-direction: column;
            align-items: center;
        }
        .user-info {
            margin-left: 3em;
            @media (max-width: 700px) {
                margin-left: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .beltGrade {
                text-transform: capitalize;
            }
            .belt-info-wrapper {
                display: flex;
                button {
                    background: #133878;
                    border: none;
                    cursor: pointer;
                    outline: none;
                    margin-left: 5px;
                    @media (min-width: 700px) {
                        display: none;
                    }
                    .icon {
                        width: 10px;
                        fill: #758ebf;
                    }
                }
            }
            h2 {
                font-size: 24px;
                margin-bottom: 0.3em;
            }
            .beltName {
                font-size: 16px;
                color: #758ebf;
            }

            .lifekeys {
                display: flex;
                margin-top: 0.7em;
            }

            .lifekey {
                background: #224384;
                margin-right: 0.5em;
            }
        }
    }
    .user-statistics-wrapper {
        display: flex;

        @media (max-width: 915px) {
            margin-top: 3em;
            justify-content: space-between;
        }
        .statistic-item {
            // width: 6.5em;
            text-align: center;
            border-right: 1px solid #19419b;
            padding-left: 0.8em;
            padding-right: 0.8em;

            @media (max-width: 469px) {
                padding-left: 3vw;
                padding-right: 3vw;
            }
            h3 {
                font-size: 21px;
                line-height: 25px;
                margin: 0;
                @media (max-width: 469px) {
                    font-size: 4.5vw;
                    line-height: 5vw;
                }
            }
            p {
                font-size: 14px;
                color: #758ebf;
                line-height: 26px;
                margin: 0;
                @media (max-width: 469px) {
                    font-size: 3vw;
                    line-height: 7vw;
                }
            }
            &:last-child {
                border: none;
            }
        }
    }
}
</style>
