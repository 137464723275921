<template>
  <div class="LifeskillBraindatesItem">
    <LifeskillNumberTag
      class="tag"
      :lifeskill-id="lifeskillId" />
    <div class="label">
      {{ $t(`dashboard.general.lifeskillLabels.${lifeskillId}`) }}
    </div>
    <div class="wrapBreak" />

    <div class="progress-wrapper">
      <!-- <div class="progress"> -->
      <div class="bar-label">
        {{ $t('videos') }}
      </div>
      <div class="bar bronze">
        <span :style="{ width: lifeskillProgress.bronze.completion + '%' }" />
      </div>
      <!-- </div> -->

      <!-- <div class="progress"> -->
      <div class="bar-label">
        {{ $t('theory') }}
      </div>
      <div class="bar silver">
        <span :style="{ width: lifeskillProgress.silver.completion + '%' }" />
      </div>
      <!-- </div> -->
      <!-- <div class="progress"> -->
      <div class="bar-label">
        {{ $t('practice') }}
      </div>
      <div class="bar gold">
        <span :style="{ width: lifeskillProgress.gold.completion + '%' }" />
      </div>
      <!-- </div> -->
    </div>
    <StandardButton
      :label="followLabel"
      class="follow --global-clickable"
      :theme="followButtonTheme"
      @click="toggleFollow" />
  </div>
</template>

<translations>
videos: Video
videos_no: Video

theory: Theory
theory_no: Teori

practice: Practice
practice_no: Praksis

follow: Follow
follow_no: Følg

unfollow: Following
unfollow_no: Følger
</translations>

<script>
import LifeskillNumberTag from '../../components/LifeskillNumberTag';
import StandardButton from '@/components/StandardButton';
import userProgress from '@/medals';

export default {
    components: { StandardButton, LifeskillNumberTag },
    props: {
        lifeskillId: {
            type: String
            // default: 'default'
        }
    },
    data() {
        return {
            isFollowed: this.$store.getters['moduleApp/follows'](this.lifeskillId)
        };
    },
    computed: {
        followLabel() {
            return this.isFollowed ? this.$t('unfollow') : this.$t('follow');
        },
        followButtonTheme() {
            return this.isFollowed ? { color: '#074fd2' } : { color: '#2a4981' };
        },
        lifeskillProgress() {
            return userProgress(this.lifeskillId);
        },
        braindates() {
            return this.$store.getters['moduleApp/braindates'].filter(bd => this.lifeskillId === bd.lifeskillNumber)
                .length;
        }
    },
    methods: {
        toggleFollow() {
            this.$store.dispatch('moduleApp/toggleLifeskillFollow', { lifeskillId: this.lifeskillId });

            this.isFollowed = !this.isFollowed;
        }
    }
};
</script>

<style lang="scss" scoped>
.LifeskillBraindatesItem {
    display: flex;
    align-items: center;
    padding: 0.5em 0;

    .StandardButton {
        /deep/ .label {
            margin-left: auto;
            margin-right: auto;
        }
    }
}
.labelContainer {
    display: flex;
    flex: 1;
}
.tag {
    margin-right: 1em;
}
.label {
    flex: 1;
    // font-weight: 300;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.braindateIcon {
    $size: 0.8em;
    width: $size;
    height: $size;
    fill: rgba(white, 0.8);
    display: block;
    margin-right: 0.5em;
    margin-left: 0.5em;
}
.braindates {
    font-weight: 300;
}

.progress-wrapper {
    display: flex;
    align-items: center;
    flex: 1;
}

.progress {
    display: flex;
    flex: 1;
    align-items: center;

    &:not(:first-child) {
        margin-left: 1em;
    }
}

.bar {
    height: 0.6em;
    // flex: 1;
    position: relative;
    background: #37588a;
    border-radius: 1em;
    overflow: hidden;
    // margin: 5px;
    margin-left: 0.5em;

    width: 5em;
}

.bar-label {
    // flex: 0.5;
    color: rgba(white, 0.7);
    margin-left: 1em;
}

.bar > span {
    display: block;
    height: 100%;
    position: relative;
    overflow: hidden;
    background-color: rgb(43, 194, 84);
}

.gold > span {
    background-color: gold;
}

.silver > span {
    background-color: silver;
}

.bronze > span {
    background-color: #917e18;
}

.follow {
    // flex: 0.5;
    height: 1.8em;
    padding: 0 0.8em;
    margin-left: 1em;

    width: 6em;
}

@include media('<800px') {
    .LifeskillBraindatesItem {
        flex-wrap: wrap;
    }
    .wrapBreak {
        width: 100%;
        height: 0.5em;
    }
    .tag {
        order: -3;
    }
    .label {
        order: -2;
    }
    .follow {
        order: -1;
        font-size: 80%;
    }

    .bar-label {
        font-size: 80%;
    }

    .progress-wrapper {
        width: 100%;
        padding-left: 3.4em;

        & > :first-child {
            margin-left: 0;
        }
    }
}
</style>
