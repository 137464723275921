<template>
  <div class="WeeklyProgressPanel">
    <div class="title">
      {{ $t('weeklyHeartTitle') }}
    </div>
    <HeartGraph :data="braindatesForCurrentWeek" />
  </div>
</template>

<translations>
  weeklyHeartTitle: Fill up your heart with daily brain dates
  weeklyHeartTitle_no: Fyll opp hjertet ditt med daglige brain dates
</translations>

<script>
import HeartGraph from './HeartGraph';

export default {
    components: { HeartGraph },
    computed: {
        braindatesForCurrentWeek() {
            return this.$store.getters['moduleApp/braindatesForCurrentWeek'];
        }
    }
};
</script>

<style lang="scss" scoped>
.WeeklyProgressPanel {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em;
    padding-top: 2em;
}

.title {
    text-align: center;
    color: rgba(white, 0.8);
}

.HeartGraph {
    width: 100%;
    max-width: 30em;
    margin-top: 2em;
}
</style>
