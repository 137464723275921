<template>
    <div class="TeamProgress">
        <div v-if="loading">
            {{ $t('loading') }}
        </div>

        <div v-if="!loading && teams.length === 0">
            {{ $t('notMember') }}
        </div>

        <team-carousel :items="leaderboard" />

        <template v-for="course in courses">
            <team-journey
                v-for="stepgroup in stepGroups(course.stepGroupIds)"
                :key="stepgroup.id"
                :course="course"
                :stepgroup="stepgroup"
                :team-id="teamId"
            />
        </template>
    </div>
</template>

<translations>
    step: Step
    step_no: Steg
    notMember: Teams are in beta. Contact Memolife to get started with your team.
    notMember_no: Teams er i beta. Kontakt Memolife for å bli med i testen.
    loading: Loading...
    loading_no: Laster...
  </translations>

<script>
import { getStepGroupTitle } from '@/utils';
import categoryThemes from '@/category-themes';
import TeamCarousel from './TeamCarousel';
import TeamJourney from './TeamJourney';
import apolloClient from '@/apollo-client';
import gql from 'graphql-tag';

export default {
    components: {
        TeamCarousel,
        TeamJourney
    },
    data: () => {
        return {
            teamJourneyProgressesList: [],
            teams: [],
            leaderboard: [],
            loading: true
        };
    },

    provide() {
        return {
            theme: categoryThemes.knowledge
        };
    },

    computed: {
        courses() {
            return Object.values(this.progress.courses).filter(c => {
                return c && c.id;
            });
        },

        teamId() {
            return this.teams[0].id;
        },

        progress() {
            return this.teamJourneyProgressesList.reduce(
                (total, p, idx, src) => {
                    total.courses[p.courseId] = total.courses[p.courseId] || this.getCourse(p.courseId);
                    if (p.activity === 'stepGroupQuiz') {
                        total.avatarsCompleted[p.stepGroupId] = total.avatarsCompleted[p.stepGroupId] || {};
                        total.avatarsCompleted[p.stepGroupId][p.avatar] = {
                            displayName: p.displayName,
                            url: p.avatar
                        };
                    } else {
                        total.avatarCurrentStep[p.stepId] = total.avatarCurrentStep[p.stepId] || {};
                        total.avatarCurrentStep[p.stepId][p.avatar] = {
                            displayName: p.displayName,
                            url: p.avatar
                        };
                    }
                    return total;
                },
                {
                    courses: {},
                    avatarsCompleted: {},
                    avatarCurrentStep: {}
                }
            );
        }
    },

    mounted() {
        apolloClient
            .query({
                query: gql`
                    query {
                        teamJourneyProgressesList {
                            avatar
                            courseId
                            displayName
                            stepId
                            stepGroupId
                            teamId
                            teamName
                            activity
                        }
                        teamsList {
                            id
                            name
                        }
                        teamLeaderboardFullsList {
                            avatar
                            braindates
                            displayName
                            teamId
                            teamName
                        }
                    }
                `
            })
            .then(result => {
                this.teamJourneyProgressesList = result.data.teamJourneyProgressesList;
                this.teams = result.data.teamsList;
                this.leaderboard = result.data.teamLeaderboardFullsList;
            })
            .finally(() => {
                this.loading = false;
            });
    },

    methods: {
        stepIds(stepGroupId) {
            return this.stepGroup(stepGroupId).stepIds;
        },

        getCourse(courseId) {
            return this.$store.state.moduleCourse.courses[courseId];
        },

        stepGroup(stepGroupId) {
            return this.$store.state.moduleCourse.stepGroups[stepGroupId];
        },

        stepGroups(stepGroupIds) {
            return stepGroupIds.map(this.stepGroup);
        },

        stepGroupTitle(stepGroup) {
            return getStepGroupTitle(stepGroup, this.$t.bind(this), this.$tc.bind(this));
        },

        step(stepId) {
            const step = this.$store.state.moduleCourse.steps[stepId];
            return step;
        },

        memo(memoId) {
            return this.$store.state.moduleCourse.memos[memoId];
        }
    }
};
</script>

<style lang="scss" scoped>
.TeamProgress {
    background-color: #102e68;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.journey {
    flex: 1;
    background-color: rgb(18, 51, 109);
    border-radius: 0.5em;
    padding: 1em;
    margin: 1em;
}

.steps {
    display: flex;
    padding: 10px;
}

.step {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.avatar {
    flex: 1;
    .Avatar {
        height: 50px;
        width: 50px;
    }
}

.stepnum {
    flex: 1;
}

.leaderboard-title {
    font-size: 22px;
    margin-top: 0;
    margin-bottom: 0.7em;
}

.leaderboard {
    display: flex;
}

.LeaderboardItem {
    flex: 1;

    background-color: rgb(18, 51, 109);
    border-radius: 0.5em;
    padding: 1em;

    &:not(:first-child) {
        margin-left: 2em;
    }

    .Avatar {
        height: 50px;
        width: 50px;
    }
}
</style>
