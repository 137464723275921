<template>
  <div class="TeamPanelGoal">
    <h2>Teams you are member of:</h2>
    <ul>
      <li
        v-for="t of teams"
        :key="t.id">
        {{ t }}
      </li>
    </ul>
    <div
      v-for="t of teams"
      :key="t.id">
      <button @click.prevent="createTeamGoal(t.id)">
        Create team goal
      </button>
    </div>
    <h2>Created Team goals:</h2>
    <ul>
      <li
        v-for="tg of teamGoals"
        :key="tg.id">
        {{ tg }}
      </li>
    </ul>
  </div>
</template>

<script>
import CreateTeamGoal from './CreateTeamGoal';
import apolloClient from '@/apollo-client';
import gql from 'graphql-tag';

export default {
    components: {},
    data: function () {
        return {
            teams: [],
            teamGoals: []
        };
    },
    mounted() {
        apolloClient
            .query({
                fetchPolicy: 'no-cache',
                query: gql`
                    query {
                        teamsList {
                            id
                            name
                        }
                        teamGoalsList {
                            id
                            shoutout
                            start
                            stop
                        }
                    }
                `
            })
            .then(result => {
                this.teams = result.data.teamsList;
                this.teamGoals = result.data.teamGoalsList;
            })
            .finally(() => {
                this.loading = false;
            });
    },
    methods: {
        createTeamGoal(teamId) {
            this.$router.push({ name: 'CreateTeamGoal', params: { teamId } });
        }
    }
};
</script>
