<template>
  <div class="ProfileMenu">
    <div class="section">
      <div
        v-for="tab of tabs"
        :key="tab.id"
        :class="{ active: tabId === tab.id }"
        class="Tab --global-clickable"
        @click="selectTab(tab.id)"
      >
        {{ isMobile ? $t(`tabs.${tab.id}`).split(' ')[0] : $t(`tabs.${tab.id}`) }}
      </div>
    </div>
  </div>
</template>

<translations>
  tabs:
    life: Overview
    life_no: Profil
    week: Weekly Heart
    week_no: Ukentlig hjerte
    follow: 'Following Lifeskills'
    follow_no: Interesser
    team: Team Progress
    team_no: Team
    settings: Settings
    settings_no: Settings
</translations>

<script>
export default {
  props: {
    tabId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tabs: [
        {
          id: 'life',
        },
        {
          id: 'week',
        },
        {
          id: 'follow',
        },
        {
          id: 'settings',
        },
      ],
      isMobile: false,
    };
  },

  created() {
    this.isMobileCheck();

    window.addEventListener('resize', this.isMobileCheck);
  },

  methods: {
    selectTab(tabId) {
      if (tabId === this.tabId) {
        return;
      }
      this.$router.push({
        name: 'DashboardProfileLifeViewWithTab',
        params: {
          tabId,
        },
      });
    },

    isMobileCheck() {
      const mobileBreakpoint = 1090;
      const screenWidth = window.screen.width;

      if (screenWidth <= mobileBreakpoint) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ProfileMenu {
  display: flex;
  justify-content: center;
  padding: 4em 0;
  @media (max-width: 590px) {
    border-top-left-radius: 25px 25px;
    border-top-right-radius: 25px 25px;
    padding: 2em 0;
  }
  .section {
    display: flex;
    width: 70em;
    justify-content: flex-start;
    @media (max-width: 1340px) {
      justify-content: center;
    }
    .Tab {
      margin-right: 1.5em;
      color: #758ebf;
      padding: 0.4em 1em;
      border-radius: 25px;
      font-weight: 500;
      font-size: 16px;
      &.active {
        background: #ede494;
        color: #102e68;
      }
      @media (max-width: 590px) {
        margin-right: 0.8em;
      }
      @media (max-width: 420px) {
        margin-right: 0.5em;
        font-size: 4vw;
        padding: 0.3em 0.7em;
      }
    }
  }
}
</style>
