<template>
  <div class="ProfileImage">
    <Avatar :profile="true" />
    <button
      class="image-change-button"
      @click="change()">
      <svgicon
        class="camera"
        icon="camerasvg" />
    </button>
  </div>
</template>

<script>
import Avatar from '@/components/Avatar';

export default {
    components: { Avatar },
    methods: {
      change() {
        this.$router.push({ name: 'ProfilePicture' });
      },
    },

    computed: {
    }
};
</script>

<style lang="scss" scoped>
.ProfileImage {
    width: 9em;
    height: 9em;
    position: relative;
    .image-change-button {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 2.5em;
        height: 2.5em;
        background: white;
        z-index: 2;
        border-radius: 50%;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .camera {
            fill: #868686;
            width: 1.1em;
        }
    }
}
</style>
