<template>
  <div class="TeamJourney">
    <div class="top-section">
      <div class="top-left-section">
        <div
          class="image-wrapper"
          :style="{ backgroundImage: `url(${course.bannerImage})` }" />
        <div class="journey-informations">
          <h3>
            {{
              course.lifeskillNumber
                ? $t(`dashboard.general.lifeskillLabels.${course.lifeskillNumber}`)
                : course.id
            }}
          </h3>
          <p>{{ $t('journey') + ' ' + romanize(stepgroup.index + 1) }}</p>
        </div>
      </div>
    </div>

    <div class="center-section">
      <div class="left-container">
        <h5>{{ $t('steps') }}</h5>
        <div
          class="steps-container"
          :style="{
            borderRight: journeyStepsThatHasScenarios.length ? '1px solid rgba(171, 171, 171, 0.2)' : ''
          }">
          <div
            v-for="stepId in stepgroup.stepIds"
            :key="stepId"
            class="step">
            <div class="icon-wrapper">
              <svgicon
                class="icon"
                icon="team-step-icon" />
            </div>
            <div
              class="players-number"
              :style="{ visibility: avatars(course.id, stepId).length ? 'visible' : 'hidden' }">
              {{ avatars(course.id, stepId).length }}
            </div>
          </div>
          <div class="step journey-quiz">
            <div class="icon-wrapper">
              <svgicon
                class="icon"
                icon="quiz-icon" />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="journeyStepsThatHasScenarios.length"
        class="right-container">
        <h5>Scenarios</h5>
        <div class="scenarios-container">
          <div
            v-for="item of journeyStepsThatHasScenarios"
            :key="item.id"
            class="scenario">
            <div
              class="icon-wrapper"
              @click="
                (isJourneyUnlocked && item.scenarios.some(scenario => scenario.done)) ||
                  (isJourneyUnlocked && currentScenarioInfo && currentScenarioInfo.stepId === item.id)
                  ? gotoLastActivity(item)
                  : null
              ">
              <div class="tooltip">
                <p>{{ item.title }}</p>

                <p
                  v-if="
                    !isJourneyUnlocked ||
                      (!item.scenarios.some(scenario => scenario.done) &&
                        currentScenarioInfo &&
                        currentScenarioInfo.stepId !== item.id)
                  "
                  class="locked-scenario-info">
                  locked
                </p>
              </div>
              <svgicon
                class="icon"
                icon="team-step-icon" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom-section">
      <div class="bottom-left-wrapper">
        <div class="team-avatars">
          <img
            class="first-image"
            src="https://via.placeholder.com/50/0000FF/808080 ?Text=Digital.com"
            alt="first team member">
          <img
            class="second-image"
            src="https://via.placeholder.com/50/FF0000/FFFFFF ?Text=Digital.com"
            alt="second team member">
          <div class="rest-members">
            {{ '+' + (leaderboard.length - 2) }}
          </div>
        </div>
        <div class="members-completed-informations">
          <div class="members-that-completed-quiz">
            {{ '0/' + leaderboard.length + ' ' + $t('members') }}
          </div>
          <p>
            {{ $t('completed') }}
          </p>
        </div>
      </div>
      <div class="bottom-right-wrapper">
        <team-game
          :course-id="course.id"
          :step-group-id="stepgroup.id"
          :team-id="teamId" />
      </div>
    </div>
  </div>
</template>

<translations>
    journey: 'Journey'
    journey_no: 'Reise'
    steps: 'Steps'
    steps_no: 'Steg'
    members: 'Members'
    members_no: 'Medlemmer'
    completed: 'Completed'
    completed_no: 'Fullført'
</translations>

<script>
import romanize from 'romanize';
import apolloClient from '@/apollo-client';
import gql from 'graphql-tag';
import { mapGetters } from 'vuex';
import TeamGame from './TeamGame';

export default {
    components: {
        TeamGame
    },

    props: {
        course: {
            type: Object,
            required: true
        },
        stepgroup: {
            type: Object,
            required: true
        },
        teamId: {
            type: Number,
            required: true
        }
    },

    data() {
        return {
            teamJourneyProgressesList: [],
            teams: [],
            leaderboard: [],
            loading: true
        };
    },

    computed: {
        ...mapGetters({
            getJourneyById: 'moduleCourse/getJourneyById',
            getStepById: 'moduleCourse/getStepById',
            getScenarioById: 'moduleCourse/getScenarioById'
        }),

        currentScenarioInfo() {
            return this.course.currentScenarioInfo;
        },

        isJourneyUnlocked() {
            return this.getJourneyById(this.stepgroup.id).quiz.done !== 0;
        },

        courses() {
            return Object.values(this.progress.courses).filter(c => {
                return c && c.id;
            });
        },

        journeyStepsThatHasScenarios() {
            return this.stepgroup.stepIds
                .map(stepId => this.getStepById(stepId))
                .filter(step => step.scenarioIds)
                .map(step => {
                    return {
                        ...step,
                        scenarios: step.scenarioIds.map(id => this.getScenarioById(id))
                    };
                });
        },

        progress() {
            return this.teamJourneyProgressesList.reduce(
                (total, p, idx, src) => {
                    total.courses[p.courseId] = total.courses[p.courseId] || this.getCourse(p.courseId);
                    if (p.activity === 'stepGroupQuiz') {
                        total.avatarsCompleted[p.stepGroupId] = total.avatarsCompleted[p.stepGroupId] || {};
                        total.avatarsCompleted[p.stepGroupId][p.avatar] = {
                            displayName: p.displayName,
                            url: p.avatar
                        };
                    } else {
                        total.avatarCurrentStep[p.stepId] = total.avatarCurrentStep[p.stepId] || {};
                        total.avatarCurrentStep[p.stepId][p.avatar] = {
                            displayName: p.displayName,
                            url: p.avatar
                        };
                    }
                    return total;
                },
                {
                    courses: {},
                    avatarsCompleted: {},
                    avatarCurrentStep: {}
                }
            );
        }
    },

    mounted() {
        apolloClient
            .query({
                query: gql`
                    query {
                        teamJourneyProgressesList {
                            avatar
                            courseId
                            displayName
                            stepId
                            stepGroupId
                            teamId
                            teamName
                            activity
                        }
                        teamsList {
                            id
                            name
                        }
                        teamLeaderboardFullsList {
                            avatar
                            braindates
                            displayName
                            teamId
                            teamName
                        }
                    }
                `
            })
            .then(result => {
                this.teamJourneyProgressesList = result.data.teamJourneyProgressesList;
                this.teams = result.data.teamsList;
                this.leaderboard = result.data.teamLeaderboardFullsList;
            })
            .finally(() => {
                this.loading = false;
            });
    },

    methods: {
        romanize(num) {
            return romanize(num);
        },

        avatars(courseId, stepId) {
            const stepGroupIds = [...this.getCourse(courseId).stepGroupIds].reverse();
            const ids = stepGroupIds
                .map(sgid => this.stepGroup(sgid))
                .filter(sg => sg.stepIds.indexOf(stepId) > -1)
                .flatMap(sg => [sg.id, ...[...sg.stepIds].reverse()]);

            let prevAvatars = [];
            for (const cur of ids) {
                let completed = this.progress.avatarsCompleted[cur] || {};
                let inProgress = this.progress.avatarCurrentStep[cur] || {};
                let avatars = { ...completed, ...inProgress };
                avatars = Object.keys(avatars)
                    .filter(a => prevAvatars.indexOf(a) === -1)
                    .map(a => avatars[a]);
                avatars.forEach(a => prevAvatars.push(a.url));
                if (cur === stepId) {
                    return Object.values(avatars);
                }
            }
            return [];
        },

        getCourse(courseId) {
            return this.$store.state.moduleCourse.courses[courseId];
        },

        stepGroup(stepGroupId) {
            return this.$store.state.moduleCourse.stepGroups[stepGroupId];
        },

        stepGroups(stepGroupIds) {
            return stepGroupIds.map(this.stepGroup);
        },

        gotoLastActivity(step) {
            const containsCourseLastScenario = this.currentScenarioInfo && step.id === this.currentScenarioInfo.stepId;

            if (containsCourseLastScenario) {
                const scenarioIndex = this.currentScenarioInfo ? this.currentScenarioInfo.scenarioIndex : 0;

                this.$router.push({
                    name: 'DashboardScenarioView',
                    params: { lifeskillId: this.course.lifeskillNumber, stepId: step.id, scenarioIndex }
                });
            } else {
                const doneStepScenarios = step.scenarios.filter(scenario => scenario.done);

                this.$router.push({
                    name: 'DashboardScenarioView',
                    params: {
                        lifeskillId: this.course.lifeskillNumber,
                        stepId: step.id,
                        scenarioIndex: doneStepScenarios.length - 1
                    }
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.locked-scenario-info {
    color: red;
    text-align: center;
}
.TeamJourney {
    width: 80vw;
    max-width: 70em;
    min-height: 200px;
    background: #fdfdfd;
    box-shadow: 0px 2px 23px rgba(79, 79, 79, 0.1);
    border-radius: 8px;
    color: black;
    margin-bottom: 1em;
    @media (max-width: 500px) {
        width: 100vw;
        margin-bottom: 2em;
    }
}
.top-section {
    display: flex;
    justify-content: space-between;
    padding: 0.5em 1em;
    border-bottom: 1px solid rgba(171, 171, 171, 0.2);
    .top-left-section {
        display: flex;
        align-items: center;

        .image-wrapper {
            min-width: 4em;
            min-height: 4em;
            border-radius: 50%;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            @media (max-width: 500px) {
                min-width: 3em;
                min-height: 3em;
            }
        }
        .journey-informations {
            margin-left: 1.2em;
            h3 {
                margin: 0;
                margin-bottom: 0.3em;
                color: #48546f;
                font-size: 20px;
            }
            p {
                margin: 0;
                font-size: 14px;
                color: #ababab;
            }
        }
    }
    .top-right-section {
        display: flex;
        margin-right: 1em;
        button {
            background: #fdfdfd;
            border: none;
            outline: none;
            cursor: pointer;
            .icon {
                fill: #c4c4c4;
                width: 3px;
            }
        }
    }
}

.center-section {
    padding-left: 95px;
    display: flex;
    border-bottom: 1px solid rgba(171, 171, 171, 0.2);
    padding-top: 0.5em;
    padding-bottom: 1.5em;
    @media (max-width: 800px) {
        flex-direction: column;
        padding-left: 18px;
        padding-bottom: 0.5em;
    }
    .left-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 1em;
    }
    .right-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 1em;
        margin-left: 1.6em;
        @media (max-width: 800px) {
            margin-left: 0;
        }
        .scenarios-container {
            display: flex;
            padding-top: 0.6em;
            @media (max-width: 800px) {
                padding-bottom: 1em;
            }

            .scenario {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-right: 1.6em;
                box-sizing: border-box;

                .icon-wrapper {
                    background: #5983f7;
                    width: 1.1em;
                    height: 1.1em;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 0.2em;
                    position: relative;
                    cursor: pointer;
                    &:hover {
                        .tooltip {
                            visibility: visible;
                        }
                    }
                    .tooltip {
                        position: absolute;
                        visibility: hidden;
                        background: #5983f7;
                        bottom: 180%;
                        left: 50%;
                        padding: 0.7em 1em;
                        z-index: 1;
                        border-radius: 0.3em 0.3em 0.3em 0;
                        color: #fdfdfd;
                        white-space: nowrap;
                        font-size: 14px;
                        &:after {
                            content: '';
                            position: absolute;
                            border-left: 8px solid #5983f7;
                            border-right: 8px solid transparent;
                            border-top: 8px solid #5983f7;
                            border-bottom: 8px solid transparent;
                            bottom: -8px;
                            left: 0;
                        }
                    }
                    @media (max-width: 800px) {
                        width: 1.6em;
                        height: 1.6em;
                    }
                    .icon {
                        fill: white;
                        width: 7px;
                        @media (max-width: 800px) {
                            width: 10px;
                        }
                    }
                }
                .players-number {
                    font-size: 14px;
                    font-weight: 500;
                    color: #ababab;
                }
            }
        }
    }
    h5 {
        font-size: 14px;
        color: #ababab;
        font-weight: initial;
        margin-bottom: 0;
        margin-top: 0;
    }
    .steps-container {
        display: flex;
        padding-top: 0.6em;
        @media (max-width: 800px) {
            flex-wrap: wrap;
        }

        .step {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-right: 1.6em;
            box-sizing: border-box;
            @media (max-width: 800px) {
                padding-right: 1.4em;
                margin-bottom: 1em;
            }

            .icon-wrapper {
                background: #5983f7;
                width: 1.1em;
                height: 1.1em;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 0.2em;
                @media (max-width: 800px) {
                    width: 1.6em;
                    height: 1.6em;
                }
                .icon {
                    fill: white;
                    width: 7px;
                    @media (max-width: 800px) {
                        width: 10px;
                    }
                }
            }
            .players-number {
                font-size: 14px;
                font-weight: 500;
                color: #ababab;
            }
        }

        .journey-quiz {
            .icon-wrapper {
                background: #eb5757;
            }
        }
    }
}

.bottom-section {
    display: flex;
    justify-content: space-between;
    @media (max-width: 800px) {
        flex-direction: column;
    }

    .bottom-left-wrapper {
        display: flex;
        align-items: center;
        @media (max-width: 800px) {
            justify-content: space-between;
            padding: 0.8em 0;
            border-bottom: 1px solid rgba(171, 171, 171, 0.2);
        }
        .team-avatars {
            display: flex;
            align-items: center;
            margin-left: 1em;
            position: relative;
            img {
                width: 1.7em;
                border-radius: 50%;
                position: absolute;
                @media (max-width: 800px) {
                    width: 30px;
                }
            }
            img:first-of-type {
                left: 0;
            }
            img:last-of-type {
                left: 1em;
            }
            .rest-members {
                position: absolute;
                left: 2.2em;
                width: 1.7em;
                height: 1.7em;
                background: #f5f5f5;
                display: flex;
                font-weight: 500;
                font-size: 12.8px;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                @media (max-width: 800px) {
                    width: 30px;
                    height: 30px;
                }
            }
        }

        .members-completed-informations {
            margin-left: 4em;
            display: flex;
            align-items: center;
            margin-right: 2em;
        }

        .members-that-completed-quiz {
            font-weight: bold;
            font-size: 14px;
        }

        p {
            font-size: 14px;
            color: #ababab;
            margin-left: 0.5em;
        }
    }

    .bottom-right-wrapper {
        display: flex;
        margin-right: 2em;
        padding: 0.5em 0 0.8em;
        @media (max-width: 800px) {
            justify-content: flex-end;
            padding: 1.5em 0;
        }
        button {
            margin-left: 0.5em;
            font-weight: 500;
            font-size: 14px;
            background: #3763dc;
            border-radius: 4.76651px;
            padding: 0 1em;
            outline: none;
            cursor: pointer;
        }
    }
}
</style>
