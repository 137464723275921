<template>
  <div
    class="WidthWrapper"
    :style="style">
    <slot />
  </div>
</template>

<script>
import ResizeObserver from 'resize-observer-polyfill';

export default {
    props: {
        setVarsOnParent: {
            type: Boolean,
            default: false
        },
        disableVars: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            parentWidth: 0,
            selfWidth: 0
        };
    },
    computed: {
        style() {
            if (!this.disableVars && !this.setVarsOnParent) {
                return {
                    '--widthWrapperSideWidth': (this.parentWidth - this.selfWidth) / 2
                };
            }
            return null;
        }
    },
    mounted() {
        if (this.disableVars) {
            return;
        }
        this.ro = new ResizeObserver((entries, observer) => {
            for (const entry of entries) {
                const { width } = entry.contentRect;
                if (entry.target === this.$el) {
                    this.selfWidth = width;
                } else {
                    this.parentWidth = width;
                }
            }
            if (this.setVarsOnParent && this.$el.parentElement) {
                this.$el.parentElement.style.setProperty(
                    '--widthWrapperSideWidth',
                    (this.parentWidth - this.selfWidth) / 2
                );
            }
        });
        this.ro.observe(this.$el);
        this.ro.observe(this.$el.parentElement);

        this.parentWidth = this.$el.parentElement.clientWidth;
        this.selfWidth = this.$el.clientWidth;

        if (this.setVarsOnParent) {
            this.$el.parentElement.style.setProperty(
                '--widthWrapperSideWidth',
                (this.parentWidth - this.selfWidth) / 2
            );
            // this.$el.parentElement.style.setProperty('--widthWrapperSideWidth', (this.parentWidth - this.selfWidth) / 2)
        }
    },
    beforeDestroy() {
        if (this.ro) {
            this.ro.disconnect();
        }
        if (this.setVarsOnParent && this.$el.parentElement) {
            this.$el.parentElement.style.removeProperty('--widthWrapperSideWidth');
        }
    }
};
</script>

<style lang="scss" scoped>
.WidthWrapper {
    width: 80%;
    margin: auto;
}

@include media('<mobile') {
    .WidthWrapper {
        width: calc(100% - 2em);
    }
}
</style>
