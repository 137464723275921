<template>
    <div v-if="leaderBoardPlayers.length" class="LeaderBoard">
        <div class="header-wrapper">
            <TeamChallengeJourneyIcon :label="journey.index + 1" class="journey-icon" />
            <div class="headers">
                <h4>
                    {{ course.lifeskillNumber }} -
                    {{ $t(`dashboard.general.lifeskillLabels.${course.lifeskillNumber}`) }}
                </h4>
                <h3>{{ journey.name }}</h3>
            </div>
        </div>

        <ul class="leader-board">
            <li v-for="(player, i) of leaderBoardPlayers" :key="player.lastName" class="leader-board-player">
                <div class="left-side">
                    <img v-if="i === 0" src="@/assets/images/golden-rank-icn.png" alt="golden rank icon" />
                    <img v-else-if="i === 1" src="@/assets/images/silver-rank-icn.png" alt="silver rank icon" />
                    <img v-else-if="i === 2" src="@/assets/images/bronze-rank-icn.png" alt="bronze rank icon" />
                    <div class="leaderbooard-4th-and-below" v-else>{{ i }}</div>
                    <p>{{ player.firstName }} {{ player.lastName || '' }}</p>
                </div>
                <div class="right-side">
                    {{ player.correct }} {{ $t('correct') }}&nbsp;
                    <span v-if="player.time.hours"> {{ player.time.hours }} {{ $t('hours') }} </span>
                    <span v-if="player.time.minutes"> {{ player.time.minutes }} {{ $t('minutes') }} </span>
                    <span v-if="player.time.seconds"> {{ player.time.seconds }} {{ $t('seconds') }} </span>
                </div>
            </li>
        </ul>
    </div>
</template>

<translations>
  correct: correct in
  correct_no: riktige på

  hours: hours
  hours_no: timer

  minutes: minutes
  minutes_no: minutter

  seconds: seconds
  seconds_no: sekunder
</translations>

<script>
import { mapGetters } from 'vuex';
import TeamChallengeJourneyIcon from './TeamChallengeJourneyIcon';
import moment from 'moment';

export default {
    components: {
        TeamChallengeJourneyIcon,
    },

    props: {
        gameId: {
            type: String,
            required: true,
        },

        teamGoal: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            leaderBoardPlayers: [],
        };
    },

    computed: {
        ...mapGetters({
            getJourneyById: 'moduleCourse/getJourneyById',
            getStepById: 'moduleCourse/getStepById',
        }),

        course() {
            return this.$store.state.moduleCourse.courses[this.journey && this.journey.courseId];
        },

        journey() {
            return this.getJourneyById(this.teamGoal.journeyId);
        },
    },

    async mounted() {
        await this.getLeaderBoard();
    },

    beforeDestroy() {
        clearInterval(this.intervalId);
    },

    methods: {
        async getLeaderBoard() {
            this.leaderBoardPlayers = await this.$store.dispatch('moduleMemoransier/getLeaderboard', this.gameId);
        },

        duration(time) {
            return moment.duration(time).humanize();
        },
    },
};
</script>

<style lang="scss" scoped>
.LeaderBoard {
    overflow: scroll;
    color: white;
    background: #18194c;
    box-shadow: 0px 2px 23px rgba(79, 79, 79, 0.1);
    border-radius: 8px;
    padding: 20px 30px;
    width: 60vw;
    @media (max-width: 1440px) {
        width: 90vw;
    }

    @media (max-width: 650px) {
        padding: 10px;
    }

    .header-wrapper {
        display: flex;
        border-bottom: 1px solid rgba(38, 32, 101, 0.5);
        align-items: flex-start;
        .journey-icon {
            min-width: 70px;
            min-height: 70px;
            max-width: 70px;
            max-height: 70px;
            @media (max-width: 450px) {
                min-width: 60px;
                min-height: 60px;
                max-width: 60px;
                max-height: 60px;
            }
        }
        .image-wrapper {
            position: relative;
            width: 52px;
            height: 52px;
            .journey-index {
                position: absolute;
                margin: 0;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 7px;
            }
            img {
                width: 100%;
                height: 100%;
            }
        }
        .headers {
            margin-left: 20px;
            padding-bottom: 10px;
            @media (max-width: 650px) {
                margin-left: 10px;
            }
            h4 {
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                color: #758ebf;
                margin: 0;
                margin-bottom: 10px;
            }

            h3 {
                font-weight: 500;
                font-size: 22px;
                line-height: 26px;
                color: white;
                margin-top: 10px;
                @media (max-width: 1200px) {
                    font-size: 18px;
                }

                @media (max-width: 450px) {
                    font-size: 16px;
                }
            }
        }
    }

    .leader-board {
        margin-top: 60px;
        list-style-type: none;
        padding: 0;
        overflow: scroll;

        .leader-board-player {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid rgba(38, 32, 101, 0.5);
            padding: 10px 0;
            .left-side {
                display: flex;
                align-items: center;
                @media (max-width: 1200px) {
                    font-size: 12px;
                }

                img {
                    width: 46px;
                    height: 46px;
                    margin-right: 20px;
                    @media (max-width: 1200px) {
                        width: 35px;
                        height: 35px;
                    }

                    @media (max-width: 450px) {
                        margin-right: 10px;
                    }
                }
                .leaderbooard-4th-and-below {
                    width: 46px;
                    height: 46px;
                    margin-right: 20px;
                    text-align: center;
                    line-height: 46px;
                    background: #322f6f;
                    color: #a4b1cd;
                    font-size: 10px;
                    border-radius: 50%;
                }
            }
            .right-side {
                display: flex;
                align-items: center;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 12px;
                display: flex;
                align-items: center;
                color: #a4b1cd;
            }
        }
    }
}
</style>
