<template>
  <div
    class="ThumbBase"
    :class="{ '--global-clickable': active, active }"
    @click="active && $emit('click')"
    @mouseover="hasHover = true"
    @mouseleave="hasHover = false">
    <div class="card">
      <div v-lazy:background-image="image" class="image" />
      <div v-if="useOverlay && image" class="imageOverlay" />
      <div class="tags">
        <slot name="tags" />
      </div>
      <div v-if="icon" class="icon">
        <svgicon class="svg-icon" :icon="icon" :style="{ fill: icon === 'checkmark' && '#309830' }" :data-icon="icon" />
      </div>

      <slot name="center" />

      <div class="spacer" />

      <div class="title" :class="{ ellipsis }" v-html="title" />
      <div v-if="subtitle" class="subtitle" :class="{ ellipsis }" v-html="subtitle" />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    image: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    ellipsis: {
      type: Boolean,
      default: true,
    },
    active: {
      type: Boolean,
      default: true,
    },
    useOverlay: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      hasHover: false,
    };
  },
};
</script>

<style lang="scss" scoped>
$transitionDuration: 0.3s;

$topPadding: 1em;
$sidePadding: 1em;
$bottomPadding: 1em;

.ThumbBase {
  position: relative;
  border-radius: $dashboard-itemBorderRadius;
  overflow: hidden;
  transform: translate3d(0, 0, 0); // Needed for rounded corners in Safari
  box-shadow: $dashboard-itemShadow;

  background-color: $dashboard-itemBackground;
  height: 100%;
  min-height: 208px;

  &.active:hover {
    .image {
      transform: scale(1.1);
    }

    .imageOverlay {
      background: linear-gradient(180deg, rgba(31, 31, 31, 0.3) 31.77%, rgba(11, 20, 38, 0.85) 100%);
    }
  }
}

.card {
  position: relative;
  transform: translate3d(0, 0, 0); // Avoid Safari visual bug, where overflow: hidden is ignored while scaling .image

  display: flex;
  flex-direction: column;

  min-height: 100%;

  padding-left: $sidePadding;
  padding-right: $sidePadding;
  padding-bottom: $bottomPadding;
}
.image,
.imageOverlay {
  transform: translate3d(
    0,
    0,
    0
  ); // Needed for safari bug https://stackoverflow.com/questions/5472802/css-z-index-lost-after-webkit-transform-translate3d
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.image {
  background-color: $dashboard-itemBackground;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transform: translate3d(
    0,
    0,
    0
  ); // Needed for safari bug https://stackoverflow.com/questions/5472802/css-z-index-lost-after-webkit-transform-translate3d
  transition: transform $transitionDuration;
}

.imageOverlay {
  background: linear-gradient(180deg, rgba(31, 31, 31, 0) 31.77%, rgba(11, 20, 38, 0.8) 100%);
  transform: translate3d(
    0,
    0,
    0
  ); // Needed for safari bug https://stackoverflow.com/questions/5472802/css-z-index-lost-after-webkit-transform-translate3d
}

.spacer {
  flex: 1;
  transform: translate3d(
    0,
    0,
    0
  ); // Needed for safari bug https://stackoverflow.com/questions/5472802/css-z-index-lost-after-webkit-transform-translate3d
}

.title {
  position: relative;
  // color: rgba(white, 0.8);
  // margin-top: 0.5em;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  transform: translate3d(
    0,
    0,
    0
  ); // Needed for safari bug https://stackoverflow.com/questions/5472802/css-z-index-lost-after-webkit-transform-translate3d
  overflow: hidden;
  font-size: 120%;

  font-weight: 600;
  // font-size: 18px;
  line-height: 122%;
  color: #e3e3e3;

  text-shadow: 0px 0px 2px rgba(67, 67, 67, 0.7);

  &.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.subtitle {
  position: relative;
  color: #d8d8d8;
  text-shadow: 0px 0px 2px rgba(67, 67, 67, 0.7);
  margin-top: 0.3em;
  transform: translate3d(
    0,
    0,
    0
  ); // Needed for safari bug https://stackoverflow.com/questions/5472802/css-z-index-lost-after-webkit-transform-translate3d

  &.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.tag {
  position: absolute;
  top: 0.5em;
  left: 0;
}

.icon {
  position: absolute;
  $size: 2.5em;
  width: $size;
  height: $size;
  top: 1em;
  right: 1em;
  transform: translate3d(
    0,
    0,
    0
  ); // Needed for safari bug https://stackoverflow.com/questions/5472802/css-z-index-lost-after-webkit-transform-translate3d
  border-radius: 50%;
  background-color: white;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0 0 0.5em rgba(black, 0.2);

  .svg-icon {
    $size: 1em;
    display: block;
    width: $size;
    height: $size;
    fill: rgba(black, 0.9);
    transform: translate3d(
      0,
      0,
      0
    ); // Needed for safari bug https://stackoverflow.com/questions/5472802/css-z-index-lost-after-webkit-transform-translate3d
    &[data-icon='campfire-2'] {
      $size: 1.4em;
      width: $size;
      height: $size;
    }
  }
}
</style>
