import { render, staticRenderFns } from "./TeamGame.vue?vue&type=template&id=6a12c55e&scoped=true&"
import script from "./TeamGame.vue?vue&type=script&lang=js&"
export * from "./TeamGame.vue?vue&type=script&lang=js&"
import style0 from "./TeamGame.vue?vue&type=style&index=0&id=6a12c55e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a12c55e",
  null
  
)

/* custom blocks */
import block0 from "./TeamGame.vue?vue&type=custom&index=0&blockType=translations"
if (typeof block0 === 'function') block0(component)

export default component.exports