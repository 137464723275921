<template>
  <div class="CarouselItem">
    <div class="profile-image-wrapper">
      <img src="https://via.placeholder.com/50" alt="avatar" />
    </div>
    <div class="user-informations-wrapper">
      <h5>{{ item.displayName }}</h5>
      <p>{{ item.braindates + ' ' + $t('brainDates') }}</p>
    </div>
  </div>
</template>

<translations>
    brainDates: 'Brain Dates'
    brainDates_no: 'Hjernedates'
</translations>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.CarouselItem {
  display: flex;
  background: #224384;
  border-radius: 4px;
  margin-right: 1em;
  padding: 1.5em 0;
  min-width: 216px;

  .profile-image-wrapper {
    display: flex;
    align-items: center;
    margin-left: 1em;

    img {
      width: 2.5em;
      height: 2.5em;
      border-radius: 50%;
    }
  }

  .user-informations-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 1em;
    padding-right: 2em;

    p {
      color: #758ebf;
      margin: 0;
      font-size: 16px;
    }

    h5 {
      margin: 0;
      font-size: 16px;
    }
  }
}
</style>
