<template>
  <div
    class="Lifekey"
    :class="{ completed }">
    <svgicon icon="key-3" />
  </div>
</template>

<script>
export default {
    props: {
        completed: {
            type: Boolean,
            defalt: false
        }
    },
    computed: {}
};
</script>

<style lang="scss" scoped>
.Lifekey {
    $size: 2.5em;
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: darken($dashboard-background, 5%);

    display: flex;
    justify-content: center;
    align-items: center;

    &.completed {
        .svg-icon {
            fill: #ede494;
        }
    }
}
.svg-icon {
    $size: 40%;
    width: $size;
    height: $size;
    fill: rgba(white, 0.3);
}
</style>
