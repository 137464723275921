<template>
  <div class="CreateTeamGoal">
    <h2>Create New Weekly Goal</h2>

    Team id: {{ teamId }} Journey id: {{ journeyId }} Created by: {{ createdBy }}
    <div
      v-for="p of packages"
      :key="p.id">
      <h3>{{ p.id }}</h3>
      <div
        v-for="id of journeys(p.lifeskillIds)"
        :key="id"
        class="journey"
        :class="{ selected: id == journeyId }"
        @click.capture="setJourney(id)">
        <JourneyItem
          :step-group-id="id"
          :hide-activities="true"
          :collapsed="true" />
      </div>
    </div>

    <form @submit.prevent="submit()">
      <label for="start">Start</label>
      <input
        id="start"
        v-model="start"
        type="date"
        required>

      <label for="stop">Stop</label>
      <input
        id="stop"
        v-model="stop"
        type="date"
        required>

      <label for="shoutout">Shoutout</label>
      <input
        id="shoutout"
        v-model="shoutout"
        type="textarea">

      <button type="submit">
        Create Now
      </button>
    </form>
  </div>
</template>

<script>
import JourneyItem from '@/modules/course/components/JourneyItem';
import gql from 'graphql-tag';
import apolloClient from '@/apollo-client';
import themes from '@/category-themes';

export default {
    components: { JourneyItem },
    filters: {},
    props: {
        teamId: {
            type: Number,
            required: true
        }
    },
    data: function () {
        return {
            shoutout: null,
            start: null,
            stop: null,
            journeyId: null
        };
    },

    computed: {
        packages() {
            const p = this.$store.state.moduleApp.packages;
            return Object.keys(p).map(id => ({ id, ...p[id] }));
        },
        createdBy() {
            return this.$store.getters['moduleAuth/profile'].id;
        }
    },
    provide: {
        theme: themes.career
    },

    mounted() {},

    methods: {
        setJourney(id) {
            console.log('setJourney', id);
            this.journeyId = id;
        },
        submit() {
            apolloClient
                .mutate({
                    fetchPolicy: 'no-cache',
                    mutation: gql`
                        mutation CreateTeamGoalMutation(
                            $createdBy: String!
                            $shoutout: String
                            $start: Datetime
                            $stop: Datetime
                            $teamId: Int!
                            $journeyId: String!
                        ) {
                            __typename
                            createTeamGoal(
                                input: {
                                    teamGoal: {
                                        createdBy: $createdBy
                                        shoutout: $shoutout
                                        start: $start
                                        stop: $stop
                                        teamId: $teamId
                                        journeyId: $journeyId
                                    }
                                }
                            ) {
                                clientMutationId
                                teamGoal {
                                    shoutout
                                    start
                                    stop
                                    teamId
                                    journeyId
                                    createdBy
                                }
                            }
                        }
                    `,
                    variables: {
                        createdBy: this.createdBy,
                        shoutout: this.shoutout,
                        start: this.start,
                        stop: this.stop,
                        teamId: this.teamId,
                        journeyId: this.journeyId
                    }
                })
                .then(result => {
                    this.$router.push({ path: '/profile/team2' });
                });
        },
        journeys(lifeskillIds) {
            lifeskillIds = lifeskillIds || [];

            const courses = Object.entries(this.$store.state.moduleCourse.courses).filter(([key, value]) => {
                return lifeskillIds.indexOf(value.lifeskillNumber) > -1;
            });
            return courses.map(([_, c]) => c.stepGroupIds).flat();
        }
    }
};
</script>

<style lang="scss" scoped>
.CreateTeamGoal {
    overflow-y: auto;
    background: linear-gradient(180deg, #0a1335 0%, #1e1456 93.87%);

    /deep/ .JourneyItem {
        .headerText {
            color: black;
        }
    }

    .journey.selected /deep/ .JourneyItem {
        .buttonToggleCollapse {
            background: #93ca4b;
        }
    }

    .journey {
        margin: 1em;
    }
    .journey.selected {
        border: 5px solid #93ca4b;
    }
}
</style>
