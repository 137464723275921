<template>
  <div class="SettingsPanelDeletePopup">
    
    <img class="image" src="./images/delete-account-illustration.svg">

    <div class="text">
      {{ $t('text') }}
    </div>

    <div class="buttons">
      <div @click="$emit('close')" class="keepButton">
        {{ $t('button1') }}
      </div>
      <div @click="deleteAccount()" class="deleteButton">
        {{ $t('button2') }}
      </div>
    </div>

  </div>
</template>

<translations>

  text: Oh no, we're sorry to see you go! We'd love to help you turn this space ship around, so don't hesitate to send us a lifeline here. By clicking on "Delete account" all your brain progress with spaced repetitions and habit training will forever be lost in space. 
  text_no: Å nei, vi er lei oss for at du vil dra! Vi skulle så gjerne ha hjulpet snu romskipet rundt, så ikke nøl med å sende oss en livslinje her. Ved å klikke på "Slett konto" vil all fremgangen din - med hjernebaserte repetisjoner og vanetrening - for alltid forsvinne i et sort hull.
  
  button1: Keep account
  button1_no: Behold konto

  button2: Delete account
  button2_no: Slett konto

</translations>

<script>

export default {
  
  methods: {
      async deleteAccount(){
          await this.$store.dispatch('moduleAuth/deleteAccount');
          this.$router.push({name: 'AccountLogoutView'});
      },
   
  },
}
</script>

<style lang="scss" scoped>

.SettingsPanelDeletePopup{
  background-image: linear-gradient(#22215E, #0b1847);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  text-align: center;
//   transition: all 1s;

  height: 37em;
  width: 28em;

  .image {
    //   background-color: blueviolet;
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
    height: 15em;
    margin-top: 2em;
  }
  .text {
      color: rgba(255, 255, 255, 0.8);
      margin-left: 2.1em;
      margin-right: 2.1em;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
    height: 12em;
    padding-left: 2em;
    padding-right: 2em;

    .keepButton {
        background:#233299;
        padding: 1em;
        border-radius: 0.2em;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.8);
        text-align: center;
        margin-bottom: 0.6em;

        &:hover {
         cursor: pointer;
        }
    }
    .deleteButton {
        background:#2333993f;
        padding: 1em;
        border-radius: 0.2em;
        font-weight: 500;
        color: white;
        text-align: center;
        color: #EC4F48;

        &:hover {
         cursor: pointer;
        }
    }
  }
  

}
</style>

