var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"TeamJourney"},[_c('div',{staticClass:"top-section"},[_c('div',{staticClass:"top-left-section"},[_c('div',{staticClass:"image-wrapper",style:({ backgroundImage: ("url(" + (_vm.course.bannerImage) + ")") })}),_c('div',{staticClass:"journey-informations"},[_c('h3',[_vm._v(" "+_vm._s(_vm.course.lifeskillNumber ? _vm.$t(("dashboard.general.lifeskillLabels." + (_vm.course.lifeskillNumber))) : _vm.course.id)+" ")]),_c('p',[_vm._v(_vm._s(_vm.$t('journey') + ' ' + _vm.romanize(_vm.stepgroup.index + 1)))])])])]),_c('div',{staticClass:"center-section"},[_c('div',{staticClass:"left-container"},[_c('h5',[_vm._v(_vm._s(_vm.$t('steps')))]),_c('div',{staticClass:"steps-container",style:({
          borderRight: _vm.journeyStepsThatHasScenarios.length ? '1px solid rgba(171, 171, 171, 0.2)' : ''
        })},[_vm._l((_vm.stepgroup.stepIds),function(stepId){return _c('div',{key:stepId,staticClass:"step"},[_c('div',{staticClass:"icon-wrapper"},[_c('svgicon',{staticClass:"icon",attrs:{"icon":"team-step-icon"}})],1),_c('div',{staticClass:"players-number",style:({ visibility: _vm.avatars(_vm.course.id, stepId).length ? 'visible' : 'hidden' })},[_vm._v(" "+_vm._s(_vm.avatars(_vm.course.id, stepId).length)+" ")])])}),_c('div',{staticClass:"step journey-quiz"},[_c('div',{staticClass:"icon-wrapper"},[_c('svgicon',{staticClass:"icon",attrs:{"icon":"quiz-icon"}})],1)])],2)]),(_vm.journeyStepsThatHasScenarios.length)?_c('div',{staticClass:"right-container"},[_c('h5',[_vm._v("Scenarios")]),_c('div',{staticClass:"scenarios-container"},_vm._l((_vm.journeyStepsThatHasScenarios),function(item){return _c('div',{key:item.id,staticClass:"scenario"},[_c('div',{staticClass:"icon-wrapper",on:{"click":function($event){(_vm.isJourneyUnlocked && item.scenarios.some(function (scenario) { return scenario.done; })) ||
                (_vm.isJourneyUnlocked && _vm.currentScenarioInfo && _vm.currentScenarioInfo.stepId === item.id)
                ? _vm.gotoLastActivity(item)
                : null}}},[_c('div',{staticClass:"tooltip"},[_c('p',[_vm._v(_vm._s(item.title))]),(
                  !_vm.isJourneyUnlocked ||
                    (!item.scenarios.some(function (scenario) { return scenario.done; }) &&
                      _vm.currentScenarioInfo &&
                      _vm.currentScenarioInfo.stepId !== item.id)
                )?_c('p',{staticClass:"locked-scenario-info"},[_vm._v(" locked ")]):_vm._e()]),_c('svgicon',{staticClass:"icon",attrs:{"icon":"team-step-icon"}})],1)])}),0)]):_vm._e()]),_c('div',{staticClass:"bottom-section"},[_c('div',{staticClass:"bottom-left-wrapper"},[_c('div',{staticClass:"team-avatars"},[_c('img',{staticClass:"first-image",attrs:{"src":"https://via.placeholder.com/50/0000FF/808080 ?Text=Digital.com","alt":"first team member"}}),_c('img',{staticClass:"second-image",attrs:{"src":"https://via.placeholder.com/50/FF0000/FFFFFF ?Text=Digital.com","alt":"second team member"}}),_c('div',{staticClass:"rest-members"},[_vm._v(" "+_vm._s('+' + (_vm.leaderboard.length - 2))+" ")])]),_c('div',{staticClass:"members-completed-informations"},[_c('div',{staticClass:"members-that-completed-quiz"},[_vm._v(" "+_vm._s('0/' + _vm.leaderboard.length + ' ' + _vm.$t('members'))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('completed'))+" ")])])]),_c('div',{staticClass:"bottom-right-wrapper"},[_c('team-game',{attrs:{"course-id":_vm.course.id,"step-group-id":_vm.stepgroup.id,"team-id":_vm.teamId}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }