<template>
  <div class="CategoryBraindatesItem">
    <div
      class="header --global-clickable"
      @click="expanded = !expanded">
      <svgicon
        v-if="showIcons"
        class="icon"
        :icon="`categories/${categoryId}`"
        width="2.2em"
        height="2.2em"
        color="white" />

      <div class="tagContainer">
        <LifeskillNumberTag
          v-if="categoryId"
          class="tag"
          :category-id="categoryId" />
      </div>
      <div class="label">
        <span v-if="categoryId">{{ $t(`dashboard.general.categoryLabels.${categoryId}`) }}</span>
        <span v-else>{{ $t('other') }}</span>
      </div>

      <div class="completed">
        {{ $t('completed') }} {{ progress }}%
      </div>
      <div
        class="expand"
        @click.stop="expanded = !expanded">
        <ButtonToggleCollapse
          :collapsed="!expanded"
          fill="#ffffff99" />
      </div>
    </div>
    <TransitionExpandCollapse>
      <div
        v-if="expanded"
        class="details">
        <LifeskillBraindatesItem
          v-for="item of children"
          :key="item"
          :lifeskill-id="item" />
      </div>
    </TransitionExpandCollapse>
  </div>
</template>

<translations>
  other: Other
  other_no: Annet

  completed: Completed
  completed_no: Fullført
</translations>

<script>
import TransitionExpandCollapse from '@/components/TransitionExpandCollapse';
import LifeskillNumberTag from '../../components/LifeskillNumberTag';
import LifeskillBraindatesItem from './LifeskillBraindatesItem';
import ButtonToggleCollapse from '@/components/ButtonToggleCollapse';
import { categoryProgress } from '@/medals';

export default {
    components: { ButtonToggleCollapse, LifeskillNumberTag, LifeskillBraindatesItem, TransitionExpandCollapse },
    props: {
        showIcons: {
            type: Boolean,
            default: true
        },
        count: {
            type: Number
        },
        categoryId: {
            type: String
        }
    },
    data: () => {
        return {
            expanded: false
        };
    },

    computed: {
        children() {
            return this.$store.getters['moduleApp/getCategoryById'](this.categoryId).lifeskills;
        },
        progress() {
            return this.children.reduce(categoryProgress, { total: 0, lifeskills: [] }).total;
        },
        braindates() {
            return this.$store.getters['moduleApp/braindates'].filter(
                bd => this.children.indexOf(bd.lifeskillNumber) > -1
            ).length;
        }
    }
};
</script>

<style lang="scss" scoped>
.CategoryBraindatesItem {
    display: block;
    padding: 0.5em 0;
    border-bottom: 1px solid rgba(white, 0.05);
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5em 0;
}

.icon {
    // $size: 1em;
    // width: $size;
    // height: $size;
    margin-right: 1em;
}

.label {
    flex: 1;
    // font-weight: 300;
    margin-left: 3.5em;
}
.tagContainer {
    // flex: 1;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 1.5em;
    width: 1em;
    .LifeskillNumberTag {
    }
}
.braindateIcon {
    $size: 0.8em;
    width: $size;
    height: $size;
    fill: rgba(white, 0.8);
    display: block;
    margin-right: 0.5em;
}
.braindates {
    font-weight: 300;
}

.completed {
    color: rgba(white, 0.7);
}

.details {
    margin-top: 0.5em;
    margin-bottom: 0.5em;

    & > *:not(:last-child) {
        border-bottom: 1px solid rgba(white, 0.05);
    }
}
</style>
